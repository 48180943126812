exports.components = {
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-desarrollo-arenales-js": () => import("./../../../src/pages/desarrolloArenales.js" /* webpackChunkName: "component---src-pages-desarrollo-arenales-js" */),
  "component---src-pages-desarrollo-carboneras-glamping-js": () => import("./../../../src/pages/desarrolloCarbonerasGlamping.js" /* webpackChunkName: "component---src-pages-desarrollo-carboneras-glamping-js" */),
  "component---src-pages-desarrollo-carboneras-js": () => import("./../../../src/pages/desarrolloCarboneras.js" /* webpackChunkName: "component---src-pages-desarrollo-carboneras-js" */),
  "component---src-pages-desarrollo-carboneras-reserva-js": () => import("./../../../src/pages/desarrolloCarbonerasReserva.js" /* webpackChunkName: "component---src-pages-desarrollo-carboneras-reserva-js" */),
  "component---src-pages-desarrollo-ocelot-js": () => import("./../../../src/pages/desarrolloOcelot.js" /* webpackChunkName: "component---src-pages-desarrollo-ocelot-js" */),
  "component---src-pages-desarrollo-punta-maya-js": () => import("./../../../src/pages/desarrolloPuntaMaya.js" /* webpackChunkName: "component---src-pages-desarrollo-punta-maya-js" */),
  "component---src-pages-desarrollo-santa-mar-js": () => import("./../../../src/pages/desarrolloSantaMar.js" /* webpackChunkName: "component---src-pages-desarrollo-santa-mar-js" */),
  "component---src-pages-desarrollo-tapirus-js": () => import("./../../../src/pages/desarrolloTapirus.js" /* webpackChunkName: "component---src-pages-desarrollo-tapirus-js" */),
  "component---src-pages-desarrollo-terus-js": () => import("./../../../src/pages/desarrolloTerus.js" /* webpackChunkName: "component---src-pages-desarrollo-terus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */)
}

